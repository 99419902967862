import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../domain/services/configService';
import { PodPromo } from '../../Pod/PodPromo';
import { PhotographersLink } from '../../responsive/atoms/PhotographersLink';
import { ParagraphWrapper } from '../ParagraphWrapper';

const PhotoOfTheDayParagraphInner = props => {
  const {
    title,
    imageEntity,
    header,
    photographer,
    galleryUrl,
    isAboveTheFold,
    preTitle
  } = props;

  const credit = photographer || imageEntity?.credit;

  const creditLabel = (
    <PhotographersLink
      photographers={imageEntity.photographers}
      color={{ text: '#fff', link: '#fff' }}
    />
  );
  const model = {
    type: 'pod',
    config: {
      sponsored: false,
      showPlayButton: false,
      channelMapping: false,
      showByline: false,
      parallax: true
    },
    link: {
      url: galleryUrl
    },
    cta: {
      linkText: t('view all'),
      inPageLink: false,
      linkUrl: galleryUrl
    },
    leadMedia: [
      {
        title: header || imageEntity?.mediaImage?.title,
        credit,
        'full-path-url': galleryUrl,
        imageEntity,
        internal: false
      }
    ],
    text: {
      brandingBadge: title,
      title: header || imageEntity?.mediaImage?.title,
      credit: creditLabel
    }
  };
  return (
    <PodPromo
      model={model}
      preTitle={preTitle}
      isAboveTheFold={isAboveTheFold}
    />
  );
};

PhotoOfTheDayParagraphInner.propTypes = {
  title: PropTypes.string,
  imageEntity: PropTypes.objectOf(PropTypes.any).isRequired,
  header: PropTypes.string,
  photographer: PropTypes.string,
  galleryUrl: PropTypes.string.isRequired,
  isAboveTheFold: PropTypes.bool,
  preTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any)
  ])
};

PhotoOfTheDayParagraphInner.defaultProps = {
  title: undefined,
  isAboveTheFold: false,
  preTitle: undefined,
  header: undefined,
  photographer: undefined
};
export const PhotoOfTheDayParagraph = ParagraphWrapper(
  PhotoOfTheDayParagraphInner
);
