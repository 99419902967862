import { css } from '@emotion/react';
import { theme } from '../../domain/theme';

export const mtCaptionBody = css`
  &,
  & .mt_caption__caption-text p {
    ${theme.font.family('boldWeb')};
    letter-spacing: 0.04em;
  }

  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;

  & .mt_caption__caption-text p {
    display: block;
    font-size: 14px;
    margin: 14px 0;
  }

  & .mt_caption__caption-text p:only-of-type {
    display: inline;
  }
`;

export const mtCaptionCntr = css`
  margin: 16px 0 36px;
`;

export const mtCaptionCntrIndent = css`
  border-left: 1px solid #e6e6e6;
  padding-left: 30px;
`;

export const mtCaptionCredit = css`
  ${theme.font.family('regularWeb')};
  letter-spacing: 0.2em;
  text-transform: uppercase;
  word-spacing: 0.05em;

  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 14px;

  &:first-of-type {
    margin: 0;
  }
`;

export const mtCaptionCreditName = css`
  ${theme.font.family('regularWeb')};
  letter-spacing: 0.2em;
  text-transform: uppercase;
  word-spacing: 0.05em;

  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5;
  @media screen and (max-width: 768px) {
    div {
      color: #000 !important;
      a {
        color: #000 !important;
      }
    }
  }
`;

export const mtCaptionTit = css`
  ${theme.font.family('regularWeb')};
  letter-spacing: 0.2em;
  text-transform: uppercase;
  word-spacing: 0.05em;

  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.43;
  padding-right: 10px;
`;

export const mtFullwidth = css`
  width: 100%;
`;

export const mtIconandlabelHorizontal = css`
  align-items: center;
  display: flex;
  flex-direction: row;

  & * {
    display: block;
  }
`;

export const mtIconandlabelVertical = css`
  align-items: center;
  display: flex;
  flex-direction: column;

  & * {
    display: block;
  }
`;

export const mtKicker = css`
  ${theme.font.family('regularWeb')};
  letter-spacing: 0.2em;
  text-transform: uppercase;
  word-spacing: 0.05em;

  border-right: 1px solid grey;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0 1.25rem;

  &:nth-of-type(1) {
    border-left: 1px solid grey;
  }
`;

export const mtKickerLink = css`
  color: inherit;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

export const mtVisuallyhidden = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &.mt_focusable:active,
  &.mt_focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
`;

export const mtBtn = css`
  ${theme.font.family('regularWeb')};
  letter-spacing: 0.2em;
  text-transform: uppercase;
  word-spacing: 0.05em;

  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: pointer;
  font-size: 0.875rem;
  margin-bottom: 0.675em;
  margin-right: 0.675em;
  padding: 1.0625rem 2.125rem;
  text-align: center;
  ${theme.font.family('regularWeb')};
  font-weight: 400;
`;

export const mtBtnNaked = css`
  background: 0 0;
`;

export const mtBtnNakedInactive = css`
  border: 0;
  color: rgba(153, 153, 153, 0.4);
  cursor: default;
`;

export const mtBtnNakedInverse = css`
  color: #fff;
`;

export const mtBtnNakedicon = css`
  color: #fc0;
  height: 10px;
  margin-left: 10px;
  width: 10px;
`;
