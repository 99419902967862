import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Image } from '../Image';
import { IconPlus } from '../Icon/IconPlus';
import { facePaint } from '../../domain/theme/utils/facePaint';
import {
  podpromo,
  podpromoContentCntr,
  podpromoContentCntrBottom,
  podpromoContentCntrLeft,
  podpromoContentCntrRight,
  podpromoContentCntrTop,
  podpromoFade,
  podpromoHeading,
  podpromoHNoCta,
  podpromoImagewrapper,
  podpromoPhotocreditCntr,
  podpromoPretit,
  podpromoTit,
  respImgLink
} from '../Styles/pod';
import {
  mtBtn,
  mtBtnNaked,
  mtCaptionCreditName,
  mtFullwidth,
  mtIconandlabelHorizontal,
  mtKicker
} from '../Styles/mt';

/*
    Tried in vain to accomplish this with css alone, but wasn't able to
    accomplish the desired layout. The purpose of this method is to wrap a
    div around the first letter in the heading. That div is used to style the
    line that matches the height of the first line in the heading
    (but not the entire height of the heading if it breaks to two lines).
  */
const wrapFirstLetter = heading => {
  if (heading) {
    const tmpString = heading.split('');
    tmpString[0] = `<div class="podpromo-h-firstletter">${tmpString[0]}</div>`;
    return tmpString.join('');
  }
  return '';
};

export const PodPromo = props => {
  const {
    model: {
      config: componentConfig,
      link: componentLink,
      leadMedia: componentImage,
      text: {
        brandingBadge: componentHeading,
        title: componentTitle,
        credit: photoCredit,
        sponsorContentLabel: sponsorLabel
      },
      cta: componentCTA
    },
    isAboveTheFold,
    preTitle
  } = props;
  const mortarCTAModel = componentCTA
    ? {
        url: componentCTA.linkUrl,
        title: componentCTA.linkText,
        target: componentCTA.linkTarget,
        seoTitle: componentCTA.linkSeoTitle
      }
    : null;

  const componentUrl = componentLink.trackingCodes
    ? `${componentLink.url}${componentLink.trackingCodes}`
    : componentLink.url;

  const headerClassNames = [
    'podpromo-heading',
    ...[mortarCTAModel && mortarCTAModel.url ? '' : 'podpromo-h--noCTA']
  ];
  const cssStyles = [
    podpromoHeading,
    mortarCTAModel && mortarCTAModel.url ? null : podpromoHNoCta
  ];
  // Adds alt and title
  if (componentImage?.[0]?.imageEntity?.mediaImage) {
    componentImage[0].imageEntity.mediaImage.alt = componentImage[0].title;
    componentImage[0].imageEntity.mediaImage.title = componentImage[0].title;
  }
  const style = {
    contentVisibility: css`
      content-visibility: auto;
      ${facePaint({
        'contain-intrinsic-size': ['0 604px', '0 567px', '0 711px', '0 891px']
      })};
    `
  };
  return (
    <div className="podpromo" css={[podpromo, style.contentVisibility]}>
      <div
        className="podpromo-content-cntr podpromo-content-cntr-top podpromo-content-cntr-left podpromo-fade"
        css={[
          podpromoContentCntr,
          podpromoContentCntrTop,
          podpromoContentCntrLeft,
          podpromoFade
        ]}
      >
        <a href={componentUrl} label="Promo">
          <div
            className={headerClassNames.join(' ')}
            css={cssStyles}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: wrapFirstLetter(componentHeading)
            }}
          />
        </a>
      </div>
      {mortarCTAModel && mortarCTAModel.url && mortarCTAModel.title && (
        <div
          className="podpromo-content-cntr podpromo-content-cntr-top podpromo-content-cntr-right podpromo-ctacontainer"
          css={[
            podpromoContentCntr,
            podpromoContentCntrTop,
            podpromoContentCntrRight
          ]}
        >
          <div className="podpromo-ctaBtn-wrapper">
            <a
              className="mt_btn "
              css={[mtBtn, mtFullwidth, mtBtnNaked]}
              href={mortarCTAModel.url}
              title={mortarCTAModel.title}
            >
              <div
                className="mt_iconandlabel--horizontal"
                css={mtIconandlabelHorizontal}
              >
                {' '}
                <span>{mortarCTAModel.title} </span>
                &nbsp;
                <IconPlus />
              </div>
            </a>
          </div>
        </div>
      )}

      <div className="podpromo-imagewrapper" css={podpromoImagewrapper}>
        <a
          href={componentUrl}
          className="resp-img-link"
          css={respImgLink}
          title={componentHeading}
          label={componentHeading}
        >
          <Image
            entity={componentImage[0].imageEntity}
            aspect="16x9"
            loading={isAboveTheFold ? 'eager' : 'lazy'}
          />
        </a>
      </div>

      <div
        className="podpromo-content-cntr podpromo-content-cntr-bottom podpromo-content-cntr-left podpromo-autoindex podpromo-fade"
        css={[
          podpromoContentCntr,
          podpromoContentCntrBottom,
          podpromoContentCntrLeft,
          podpromoFade
        ]}
      >
        {componentConfig.sponsored ? (
          <div className="mt_kicker mt_kicker--sponsored" css={mtKicker}>
            {sponsorLabel}
          </div>
        ) : null}
        {preTitle && (
          <div className="podpromo-pretit" css={podpromoPretit}>
            {preTitle}
          </div>
        )}
        <div
          className="podpromo-tit"
          css={podpromoTit}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: componentTitle }}
        />
      </div>

      <div
        className="podpromo-content-cntr podpromo-content-cntr-bottom podpromo-content-cntr-right podpromo-fade"
        css={[
          podpromoContentCntr,
          podpromoContentCntrBottom,
          podpromoContentCntrRight,
          podpromoFade
        ]}
      >
        <div
          className="podpromo-photocredit-cntr"
          css={podpromoPhotocreditCntr}
        >
          {photoCredit && (
            <div
              className="mt_caption-creditname podpromo-photocredit"
              css={[mtCaptionCreditName]}
            >
              {photoCredit}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PodPromo.defaultProps = {
  isAboveTheFold: false,
  preTitle: undefined
};

PodPromo.propTypes = {
  model: PropTypes.shape({
    type: PropTypes.string.isRequired,
    config: PropTypes.shape({
      parallax: PropTypes.bool,
      sponsored: PropTypes.bool
    }),
    link: PropTypes.shape({
      url: PropTypes.string,
      target: PropTypes.string,
      trackingCodes: PropTypes.string
    }),
    // come back for leadMedia for shaping
    leadMedia: PropTypes.arrayOf(
      PropTypes.shape({
        imageEntity: PropTypes.objectOf(PropTypes.any).isRequired,
        title: PropTypes.string,
        alt: PropTypes.string,
        aspectRatio: PropTypes.number,
        height: PropTypes.number,
        width: PropTypes.number,
        // eslint-disable-next-line react/forbid-prop-types
        srcset: PropTypes.array
      })
    ),
    text: PropTypes.shape({
      brandingBadge: PropTypes.string,
      title: PropTypes.string,
      credit: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(PropTypes.any)
      ]),
      sponsorContentLabel: PropTypes.string
    }),
    cta: PropTypes.shape({
      linkUrl: PropTypes.string,
      linkText: PropTypes.string,
      linkTarget: PropTypes.string,
      linkSeoTitle: PropTypes.string
    })
  }).isRequired,
  isAboveTheFold: PropTypes.bool,
  preTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any)
  ])
};
